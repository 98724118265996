<template>
  <transition name="modal">
    <div v-if="show" class="modal-mask" @click="$emit('close')">
      <div class="modal-container" @click.stop="">
        <div class="modal-header">
          <slot name="header">default header</slot>
          <span style="position: absolute; right: 4px; top: 4px;cursor: pointer; font-size: 10px" @click="$emit('close')">&#10060;</span>
        </div>

        <div class="modal-body">
          <div name="body" id="body">
            <div class="body-content">
              <div class="body-content-text">
                <div class="resisupport">
                  <div> 
                    <div class="content-headers"> Resistance </div> 
                    <div> <label class="sub-headers"> R1 </label> <label class="resistance"> {{parseInt(resistance[0])}}.00 </label> </div>
                    <div> <label class="sub-headers"> R2 </label> <label class="resistance"> {{parseInt(resistance[1])}}.00 </label> </div>
                  </div>
                  <div> 
                    <div class="content-headers"> Support </div> 
                    <div> <label class="sub-headers"> S1 </label> <label class="support"> {{parseInt(support[0])}}.00 </label> </div> 
                    <div> <label class="sub-headers"> S2 </label> <label class="support"> {{parseInt(support[1])}}.00 </label> </div> 
                  </div>
                </div>
                <div>
                  <div id="recos-tabs">
                    <div :class="currentTab == 1? 'active': ''" @click="currentTab = 1">Breakouts</div>
                    <div :class="currentTab == 2? 'active': ''" @click="currentTab = 2">Reversals</div>
                  </div>
                  <div v-if="currentTab == 1" id="recos-container" class="resisupport">
                    <div v-if="selectedType == 'Call'"> 
                      <div class="resisupport" style="grid-auto-flow: row;">
                        <div>
                          <div class="content-headers"> <span> Buy Above </span> </div>
                          <div class="resistance"> <span> <label> {{parseInt(doBuy)}}.00 </label> </span> </div>
                        </div>
                        <div>
                          <div class="content-headers"> <span> Stop Loss </span> </div>
                          <div class="support"> <span> <label> {{parseInt(doSell)}}.00 </label> </span> </div>
                        </div>
                      </div>
                      <div style="display: grid; grid-gap: 4px;">
                        <div class="content-headers"> <span> Possible Targets </span> </div>
                        <div> <span> <label class="sub-headers"> T1 </label> <label> {{parseInt((parseFloat(doResistance[0]) * 0.9995).toFixed(2))}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T2 </label> <label> {{parseInt((parseFloat(doResistance[1]) * 0.9995).toFixed(2))}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T3 </label> <label> {{parseInt((parseFloat(doResistance[2]) * 0.9995).toFixed(2))}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T4 </label> <label> {{parseInt((parseFloat(doResistance[3]) * 0.9995).toFixed(2))}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T5 </label> <label> {{parseInt((parseFloat(doResistance[4]) * 0.9995).toFixed(2))}}.00 </label> </span> </div>
                        <!-- <span> <span style="font-weight: bold"> T2: </span> {{(parseFloat(resistance) * 0.9995).toFixed(2)}}  </span> -->
                      </div>
                    </div>
                    <div v-if="selectedType == 'Put'"> 
                      <div class="resisupport" style="grid-auto-flow: row;">
                        <div>
                          <div class="content-headers"> <span> Sell Below  </span> </div>
                          <div class="support"> <span> <label> {{parseInt(doSell)}}.00 </label> </span> </div>
                        </div>
                        <div>
                          <div class="content-headers"> <span> Stop Loss </span> </div>
                          <div class="resistance"> <span> <label> {{parseInt(doBuy)}}.00 </label> </span> </div>
                        </div>
                      </div>
                      <div style="display: grid; grid-gap: 4px;">
                        <div class="content-headers"> <span> Possible Targets </span> </div>
                        <div> <span> <label class="sub-headers"> T1 </label> <label> {{parseInt((parseFloat(doSupport[0]) * 1.0005).toFixed(2))}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T2 </label> <label> {{parseInt((parseFloat(doSupport[1]) * 1.0005).toFixed(2))}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T3 </label> <label> {{parseInt((parseFloat(doSupport[2]) * 1.0005).toFixed(2))}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T4 </label> <label> {{parseInt((parseFloat(doSupport[3]) * 1.0005).toFixed(2))}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T5 </label> <label> {{parseInt((parseFloat(doSupport[4]) * 1.0005).toFixed(2))}}.00 </label> </span> </div>
                        <!-- <span> <span style="font-weight: bold"> T1: </span> {{(parseFloat(support) * 1.0005).toFixed(2)}} </span> -->
                      </div>
                    </div>
                  </div>
                  <div v-if="currentTab == 2" id="recos-container" class="resisupport">
                    <div v-if="selectedType == 'Call'"> 
                      <div class="resisupport" style="grid-auto-flow: row; grid-gap: 10px">
                        <div>
                          <div class="content-headers"> <span> Possible Reversal Zone </span> </div>
                          <div class="resistance"> <span> <label> {{parseInt((parseFloat(doSupport[1]) * 1.0005).toFixed(2))}}.00 - {{parseInt(((parseFloat(doSupport[1]) + 0.5) * 0.9995).toFixed(2))}}.00 </label> </span> </div>
                        </div>
                        <div>
                          <div class="content-headers"> <span> Stop Loss Below </span> </div>
                          <div class="support"> <span> <label> {{parseInt((parseFloat(doSupport[2]) * 1.0005).toFixed(2))}}.00 </label> </span> </div>
                        </div>
                      </div>
                      <div>
                        <div class="content-headers"> <span> Possible Targets </span> </div>
                        <div> <span> <label class="sub-headers"> T1 </label> <label> {{parseInt(doSupport[0])}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T2 </label> <label> {{parseInt(doBuy)}}.00 </label> </span> </div>
                      </div>
                    </div>
                    <div v-if="selectedType == 'Put'"> 
                      <div class="resisupport" style="grid-auto-flow: row; grid-gap: 10px">
                        <div>
                          <div class="content-headers"> <span> Possible Reversal Zone  </span> </div>
                          <div class="support"> <span> <label> {{parseInt((parseFloat(doResistance[1]) * 0.9995).toFixed(2))}}.00 - {{parseInt(((parseFloat(doResistance[1])) * 1.0005).toFixed(2))}}.00 </label> </span> </div>
                        </div>
                        <div>
                          <div class="content-headers"> <span> Stop Loss </span> </div>
                          <div class="resistance"> <span> <label> {{parseInt((parseFloat(doResistance[2]) * 0.9995).toFixed(2))}}.00 </label> </span> </div>
                        </div>
                      </div>
                      <div>
                        <div class="content-headers"> <span> Possible Targets </span> </div>
                        <div> <span> <label class="sub-headers"> T1 </label> <label> {{parseInt(doResistance[0])}}.00 </label> </span> </div>
                        <div> <span> <label class="sub-headers"> T2 </label> <label> {{parseInt(doSell)}}.00 </label> </span> </div>
                        <!-- <span> <span style="font-weight: bold"> T1: </span> {{(parseFloat(support) * 1.0005).toFixed(2)}} </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="false" class="body-content-chart">
                <tvChart></tvChart>
              </div>
            </div>
            <div class="investment-disclaimer">
              Recomendation provided is for educational and informational purposes only. stock trading is inherently risky and the users agree to assume complete and full responsibility for the outcomes of all trading decisions that they make, including but not limited to loss of capital.
            </div>
          </div>
        </div>

        <div class="modal-footer" style="text-align: end">
          <slot name="footer">
            <!-- default footer -->
            <button
              v-if="false"
              class="modal-default-button"
              @click="$emit('close')"
            >Got it!</button>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import tvChart from './tvChart.vue';
export default {
  data() {
    return {
      currentTab: 1
    }
  },
  props: {
    show: Boolean,
    resistance: Array,
    support: Array,
    doResistance: Array,
    doSupport: Array,
    selectedType: String,
    buy: Number,
    sell: Number,
    doBuy: Number,
    doSell: Number
  },
  components: {
      tvChart
  },
}
</script>
<style>
#recos-tabs {
  width: fit-content;
  /* margin: auto; */
  display: grid;
  grid-auto-flow: column;
}
#recos-tabs div {
  padding: 4px 12px;
  font-weight: bold;
  color: #2c3e50;
  cursor: pointer;
  font-size: 15px;
}
#recos-tabs .active {
  background: #2c3e50;
  color: white;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #2c3e50;
  border-bottom: none;
}
#recos-container {
  padding: 20px 0;
  border: 1px inset #2c3e5045;
  border-radius: 0px 4px 4px 4px;
}
#recos-container > div {
  display: grid; 
  grid-auto-flow: column;
}
.content-headers {
  padding-bottom: 4px!important;
  font-weight: bold;
  font-size: 0.8em;
}
.modal-body #body {
  display: grid;
  grid-gap: 16px;
}
.resisupport {
  display: grid;
  grid-auto-flow: column;
  /* gap: 20px; */
}

.resisupport > div > div {
  padding-bottom: 4px;
}

.resisupport .resistance {
  color: green;
}

.resisupport .support {
  color: darkred;
}

.resisupport label {
  /* font-weight: bold; */
  font-size: 0.9em;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 24%;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  text-align: left;
}

.modal-container span {
  padding-left: 12px;
}

.modal-header {
  position: relative;
}

.modal-header div {
  font-size: 20px;
  font-weight: bold;
  /* color: #42b983; */
}

.modal-body {
  margin-top: 20px;
}

.modal-default-button {
  /* margin-top: 10px; */
  padding: 7px 14px;
  background: #2c3e50;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
}

.sub-headers {
  font-weight: bold;
  font-size: 0.8em!important;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.investment-disclaimer {
  font-size: 0.5em;
  margin: 0 4px;
  /* text-align: justify; */
  /* text-transform: uppercase; */
}

.body-content {
  display: grid;
  /* grid-template-columns: 300px 1fr; */
  grid-gap: 20px;
}

.body-content-text {
  display: grid;
  grid-gap: 16px;
}

@media only screen and (max-width: 600px) {
  .modal-container {
    width: 80%;
  }
}
</style>