<template>
  <Home msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Home from './components/home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* font-size: 16px; */
  /* overflow: scroll; */
}

body {
  margin: 0;
  /* overflow: scroll; */
}

html {
  /* overflow: scroll; */
}

.jira-elements {
  position: fixed;
  background: #214797;
  padding: 5px;
  border: 2px solid white;
  border-top: none;
  font-weight: bold;
  color: white !important;
  display: block;
  white-space: nowrap;
  text-decoration: none !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 5px 5px;
  transform: rotate(90deg);
  transform-origin: top left;
  /* background: #f8d629; */
  /* color: black!important; */
  font-weight: normal;
  cursor: pointer;
}

#feedback-jira {
  left: 100%;
  top: 80%;
}

#report-issue-jira {
  left: 100%;
  top: 65%;
}
@media only screen and (max-width: 600px) {
  #app {
    font-size: 0.8em;
  }
}
</style>
