<template>
  <div>
    <div class="header sticky-header sticky-1" style="display: grid; grid-template-columns: 1fr 240px; position: fixed; height: 60px; z-index: 10">
      <div id="logo">
        <span> Humble <span id="option-chain-brand"> Option Chain </span> </span>
        <div class="release-version">
          V 1.1.1 Beta Release 
          <span class="tooltip">
            <i style="cursor: default" class="fa fa-info-circle"></i> 
            <span class="tooltipText">
              <div>
                This website is in a beta release and we are continously working on its improvements. Please provide feedback to make it better using the action buttons on the right.
              </div>
              <div>
                <div class="doc-release-version"><span>Version 1.1.1</span></div>
                <div>
                  <ul>
                    <li>Issue Fix on expiry selection</li>
                  </ul>
                </div>
                <div class="doc-release-version"><span>Version 1.1.0</span></div>
                <div>
                  <ul>
                    <li>Added Support for FINNIFTY</li>
                    <li>Improvements on Recommendation System</li>
                    <li>Telegram Link to free live trades channel</li>
                    <li>Improvements on Analytics</li>
                    <li>Issues cleanup reported by users</li>
                  </ul>
                </div>
                <div class="doc-release-version"><span>Version 1.0.0</span></div>
                <div>
                  <ul>
                    <li>Initial stable website</li>
                    <li>Supports NIFTY and BANKNIFTY</li>
                    <li>Breakout and reversal Recommendation System</li>
                    <li>Users are able to provide feedback and report issues</li>
                    <li>Data refreshes every 5 minutes</li>
                  </ul>
                </div>
              </div>
            </span>
          </span> 
        </div>
      </div>
      <div class="input-selection-container" style="display: grid; grid-template-columns: 120px 120px;">
        <div style="line-height: 20px;">
          <label style="font-size: 12px; font-weight: bold;">Symbol</label>
          <select @change="symbolChangedEvent($event)" style="width: fit-content">
            <option v-for="(symbol, index) in symbols" :key="index">{{symbol}}</option>
          </select>
        </div>
        <div style="line-height: 20px;">
          <label style="font-size: 12px; font-weight: bold;">Expiry</label>
          <select @change="expiryChangedEvent($event)" style="width: fit-content">
            <option v-for="(data, index) in globalData" :key="index" :selected="index == currentExpiry">{{index}}</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="!isMarketOpen" id="closedBannerTop"><label>Stock market next opens in <span style="font-weight: bold"> {{countDownDays}}d : {{countDownHours}}h : {{countDownMinutes}}m : {{countDownSeconds}}s </span></label></div>
    <div v-if="!isMarketOpen && showClosedBanner" id="market-closed-banner" @click="showClosedBanner = false">
      <div style="width: fit-content; margin: auto; padding: 70px; margin-top: 10%; background: white; box-shadow: 0 5px 20px 0 rgba(0,0,0,0.2), 0 0px 5px 0 rgba(0,0,0,0.19); position: relative; max-width: 100vw;" @click.stop="">
        <span style="position: absolute; right: 30px; top: 30px;cursor: pointer" @click="showClosedBanner = false">&#10060;</span>
        <div style="display: grid; grid-auto-flow: column;">
          <div id="sm-stock-market" style="margin-bottom: 30px;">
            <img id="sm-stock" src="@/assets/stock.svg" style="width: 300px; margin-right: 50px;"/>
            <img id="sm-market" src="@/assets/market.svg" style="width: 370px;"/>
          </div>
        </div>
        <div>
          <img id="sm-closed" src="@/assets/closed.svg" style="width: 350px;"/>
        </div>
        <div style="margin-top: 40px; font-size: 1.5em;"> The market next opens in <span class="countdown-timer-popup" style="font-weight: bold"> {{countDownDays}}d : {{countDownHours}}h : {{countDownMinutes}}m : {{countDownSeconds}}s </span> </div>
        <div style="margin-top: 20px; font-size: 1.5em;">You can analyse the data of the previous market session by closing this popup.</div>
      </div>
    </div>
    <div>
      <div class="option-chain-table sticky-header"  :class="(isMarketOpen)? 'sticky-2': 'sticky-2-with-banner'" style="display: none;">
        <div class="option-chain-call-table option-chain-call-headers option-chain-table-row">Call</div>
        <div class="option-chain-strike-table option-chain-strike-headers">
          <div style="margin-top: 4px;">
            <div style="font-size: 0.75em">Expiry</div>
            <select @change="expiryChangedEvent($event)">
              <option v-for="(data, index) in globalData" :key="index">{{index}}</option>
            </select>
          </div>
        </div>
        <div class="option-chain-put-table option-chain-put-headers option-chain-table-row">Put</div>
      </div>
      <div id="content-table" class="option-chain-table">
        <div class="option-chain-call-table">
          <div class="option-chain-table-row option-chain-call-table-row option-chain-call-headers sticky-header" :class="(isMarketOpen)? 'sticky-3': 'sticky-3-with-banner'">
            <div class="option-chain-call-table-col-header">IV</div>
            <div class="option-chain-call-table-col-header">OI</div>
            <div class="option-chain-call-table-col-header">Volume</div>
            <div class="option-chain-call-table-col-header">Call LTP</div>
          </div>
          <div @click="showRecos('Call', data['strikePrice'])" class="option-chain-data-row option-chain-table-row option-chain-call-table-row" :class="this.currentStrike == data['strikePrice']? 'current-strike': ''" v-for="data in ocData" :key="data['strikePrice'] + '-ce'">
            <div class="option-chain-call-table-col line-height-40">
              <span v-if="'CE' in data && 'impliedVolatility' in data['CE'] && data['CE']['impliedVolatility'] > 0">{{ data['CE']['impliedVolatility'] }}</span> 
              <span v-else>-</span>
            </div>
            <div v-if="'CE' in data && 'openInterest' in data['CE'] && data['CE']['openInterest'] > 0" class="option-chain-call-table-col option-chain-call-table-col-with-percent">
              <div> {{ data['CE']['openInterest'].toLocaleString('hi') }} </div>
              <div class="percent-change" :class="data['CE']['pchangeinOpenInterest'] > 0? 'percent-below' : (data['CE']['pchangeinOpenInterest'] == 0)? '' : 'percent-above' "> <span v-if="data['CE']['pchangeinOpenInterest'] > 0"> &#x25B2; </span> <span v-if="data['CE']['pchangeinOpenInterest'] < 0"> &#x25BC; </span> {{ parseFloat(data['CE']['pchangeinOpenInterest']).toFixed(2).toLocaleString('hi') }}% </div>
            </div>
            <span v-else class="unfilled-void">-</span>
            <div v-if="'CE' in data && 'totalTradedVolume' in data['CE'] && data['CE']['totalTradedVolume'] > 0" class="option-chain-call-table-col line-height-40">{{ data['CE']['totalTradedVolume'].toLocaleString('hi') }} </div>
            <span v-else>-</span>
            <div v-if="'CE' in data && 'lastPrice' in data['CE'] && data['CE']['lastPrice'] > 0" class="option-chain-call-table-col option-chain-call-table-col-with-percent">
              <div> &#8377;{{ data['CE']['lastPrice'] }} </div>
              <div class="percent-change" :class="data['CE']['pChange'] > 0? 'percent-below' : (data['CE']['pChange'] == 0)? '' : 'percent-above' "> <span v-if="data['CE']['pChange'] > 0"> &#x25B2; </span> <span v-if="data['CE']['pChange'] < 0"> &#x25BC; </span> {{ parseFloat(data['CE']['pChange']).toFixed(2).toLocaleString('hi') }}% </div>
            </div>
            <span v-else class="unfilled-void">-</span>
          </div>
        </div>
        <div class="option-chain-strike-table">
          <div class="option-chain-table-row option-chain-call-table-col-header option-chain-strike-headers sticky-header" :class="(isMarketOpen)? 'sticky-3': 'sticky-3-with-banner'">Strike Price</div>
          <div v-for="data in ocData" :key="data['strikePrice'] + '-sp'" class="option-chain-table-row option-chain-strike-col option-chain-call-table-col strike-price-val line-height-40" :class="this.currentStrike == data['strikePrice']? 'current-strike': ''" :ref="(this.currentStrike == data['strikePrice'])? 'targetRef' : ''">
            <div>{{ data['strikePrice'].toLocaleString('hi') }}</div>
          </div>
        </div>
        <div class="option-chain-put-table">
          <div class="option-chain-table-row option-chain-put-table-row option-chain-put-headers sticky-header" :class="(isMarketOpen)? 'sticky-3': 'sticky-3-with-banner'">
            <div class="option-chain-call-table-col-header">Put LTP</div>
            <div class="option-chain-call-table-col-header">Volume</div>
            <div class="option-chain-call-table-col-header">OI</div>
            <div class="option-chain-call-table-col-header">IV</div>
          </div>
          <div @click="showRecos('Put', data['strikePrice'])" class="option-chain-data-row option-chain-table-row option-chain-put-table-row" :class="this.currentStrike == data['strikePrice']? 'current-strike': ''" v-for="data in ocData" :key="data['strikePrice'] + '-pe'">
            <div v-if="'PE' in data && 'lastPrice' in data['PE'] && data['PE']['lastPrice'] > 0" class="option-chain-call-table-col option-chain-call-table-col-with-percent">
              <div> &#8377;{{ data['PE']['lastPrice'] }} </div>
              <div class="percent-change" :class="data['PE']['pChange'] > 0? 'percent-below' : (data['PE']['pChange'] == 0)? '' : 'percent-above'"> <span v-if="data['PE']['pChange'] > 0"> &#x25B2; </span> <span v-if="data['PE']['pChange'] < 0"> &#x25BC; </span> {{ parseFloat(data['PE']['pChange']).toFixed(2).toLocaleString('hi') }}% </div>
            </div>
            <span v-else class="unfilled-void">-</span>
            <div v-if="'PE' in data && 'totalTradedVolume' in data['PE'] && data['PE']['totalTradedVolume'] > 0" class="option-chain-call-table-col line-height-40">{{ data['PE']['totalTradedVolume'].toLocaleString('hi') }} </div>
            <span v-else>-</span>
            <div v-if="'PE' in data && 'openInterest' in data['PE'] && data['PE']['openInterest'] > 0" class="option-chain-call-table-col option-chain-call-table-col-with-percent">
              <div> {{ data['PE']['openInterest'].toLocaleString('hi') }} </div>
              <div class="percent-change" :class="data['PE']['pchangeinOpenInterest'] > 0? 'percent-below' : (data['PE']['pchangeinOpenInterest'] == 0)? '' : 'percent-above' "> <span v-if="data['PE']['pchangeinOpenInterest'] > 0"> &#x25B2; </span> <span v-if="data['PE']['pchangeinOpenInterest'] < 0"> &#x25BC; </span> {{ parseFloat(data['PE']['pchangeinOpenInterest']).toFixed(2).toLocaleString('hi') }}% </div>
            </div>
            <span v-else class="unfilled-void">-</span>
            <div class="option-chain-call-table-col line-height-40">
              <span v-if="'PE' in data && 'impliedVolatility' in data['PE'] && data['PE']['impliedVolatility'] > 0">{{ data['PE']['impliedVolatility'] }}</span> 
              <span v-else>-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popupModal :show="showModal" :resistance="strikeResistance" :support="strikeSupport" :buy="strikeBuy" :sell="strikeSell"  :doBuy="doBuy" :doSell="doSell" :doResistance="doResistance" :doSupport="doSupport" :selectedType="selectedType" @close="showModal = false">
      <template #header>
        <div>Strike {{selectedStrike}} - {{selectedType}}</div>
      </template>
    </popupModal>
  </div>
</template>

<script>
  window.ATL_JQ_PAGE_PROPS = window.jQuery.extend(window.ATL_JQ_PAGE_PROPS, {
    'b22a7bfc' : {        
      triggerFunction: function(showCollectorDialog) {
        //Requires that jQuery is available! 
        window.jQuery("#feedback-jira").click(function(e) {
          e.preventDefault();
          showCollectorDialog();
        });
      }
    },
    'bb104776' : {        
      triggerFunction: function(showCollectorDialog) {
        //Requires that jQuery is available! 
        window.jQuery("#report-issue-jira").click(function(e) {
          e.preventDefault();
          showCollectorDialog();
        });
      }
    }
  });
// document.addEventListener('contextmenu', function(e) {
//   e.preventDefault();
// });
import popupModal from './popupModal.vue';
export default {
  name: 'OCHome',
  data() {
    return {
      globalData: [],
      ocData: [],
      dayOpen: 0,
      currentExpiry: "",
      currentSymbol: "",
      currentStrike: 0,
      firstStrike: 0,
      lastStrike: 0,
      showModal: false,
      selectedStrike: 0,
      strikeResistance: [],
      strikeSupport: [],
      strikeBuy: 0,
      strikeSell: 0,
      marketOpen: "weekdays",
      isMarketOpen: true,
      marketOpenHour: 9,
      marketOpenMinutes: 15,
      marketCloseHour: 15,
      marketCloseMinutes: 30,
      doBuy: 0,
      doSell: 0,
      doResistance: [],
      doSupport: [],
      showClosedBanner: true,
      selectedType: "",
      countDownTime: 0,
      countDownDays: 0,
      countDownHours: 0,
      countDownMinutes: 0,
      countDownSeconds: 0,
      countDownDistance: 0,
      symbols: ["NIFTY", "BANKNIFTY", "FINNIFTY"],
    }
  },
  props: {
    msg: String
  },
  mounted: function() {
    var self = this;
    window.jQuery("#feedback-jira").show();
    window.jQuery("#report-issue-jira").show();
    this.currentSymbol = this.symbols[0];
    this.initializeData(false, true);
    setInterval(function () {
      self.initializeData();
    }, 10000);
  },
  components: {
    popupModal
  },
  methods: {
    showRecos(strikeType, strikePrice) {
      [this.strikeResistance, this.strikeSupport, this.strikeBuy, this.strikeSell] = this.calculateGannSquareOf9(strikePrice);
      this.showModal = true; 
      this.selectedStrike = strikePrice; 
      this.selectedType= strikeType;
    },
    initializeData(skip = false, firstTime = false) {
      let date = new Date();
      if (!this.isMarketHours(date) && this.ocData.length > 0 && !skip) {
        return false;
      }
      if (firstTime || skip || (date.getMinutes() % 5 == 0 && date.getSeconds() == 10)) {
        this.fetchDayOpen(this.currentSymbol);
        this.fetchData(this.currentSymbol);
      }
    },
    expiryChangedEvent(event) {
      this.currentExpiry = event.target.value;
      this.loadData(this.globalData[this.currentExpiry]);
    },
    symbolChangedEvent(event) {
      this.currentSymbol = event.target.value;
      this.strikeBuy = 0;
      this.strikeSell = 0;
      this.dayOpen = 0;
      this.initializeData(true);
    },
    loadData(data) {
      this.ocData = [];
      for (let i = 0; i < data.length; i++) {
        // let strikePrice = OCData['data']['03-Aug-2023'][i]['strikePrice'];
        // if (self.firstStrike < strikePrice && strikePrice < self.lastStrike) {
          this.ocData.push(data[i]);
        // }
      }
      this.scrollToView();
    },
    isWeekend(date) {
      return (date.getDay() === 6 || date.getDay() === 0)? true : false;
    },
    isMarketHours(date) {
      if (!this.isWeekend(date)) {
        if (this.marketOpenHour <= date.getHours() && date.getHours() <= this.marketCloseHour) {
          if (this.marketOpenHour == date.getHours()) {
            if (this.marketOpenMinutes <= date.getMinutes()) {
              return true;
            }
            else {
              return false;
            }
          }
          else if (date.getHours() == this.marketCloseHour) {
            if (date.getMinutes() <= this.marketCloseMinutes) {
              return true;
            }
            else {
              return false;
            }
          }
          return true;
        }
      }
      return false;
    },
    getPrevMarketClose(date) {
      // If it is Sunday then fetch Friday
      if (date.getDay() === 0) {
        date.setDate(date.getDate() - 2);
      }
      // If it is Saturday then fetch Friday
      else if (date.getDay() === 6) {
        date.setDate(date.getDate() - 1);
      }
      // If it is weekdays
      else {
        // If it is weekday pre open, get previous day
        if (date.getHours() <= this.marketOpenHour && date.getMinutes() < this.marketOpenMinutes) {
          // If it is Monday, get Friday
          // console.log("PRE");
          // console.log(date.getDay());
          if (date.getDay() === 1) {
            date.setDate(date.getDate() - 3);
          }
          // Else get prev day
          else {
            date.setDate(date.getDate() - 1);
          }
        }
        else {
          date.setDate(date.getDate());
        }
      }
      var day = (date.getDate() < 10)? "0" + date.getDate() : date.getDate();
      var month = (date.getMonth() + 1 < 10)? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      return day + "-" + month + "-" + date.getFullYear();
    },
    getNextMarketOpen(date) {
      // If it is Sunday then fetch Monday
      if (date.getDay() === 0) {
        date.setDate(date.getDate() + 1);
      }
      // If it is Saturday then fetch Monday
      else if (date.getDay() === 6) {
        date.setDate(date.getDate() + 2);
      }
      // If it is weekdays
      else {
        // If it is weekday pre open, get previous day
        if (date.getHours() > this.marketOpenHour) {
          // If it is Friday, get Monday
          if (date.getDay() === 5) {
            date.setDate(date.getDate() + 3);
          }
          // Else get prev day
          else {
            date.setDate(date.getDate() + 1);
          }
        }
      }
      // var day = (date.getDate() < 10)? "0" + date.getDate() : date.getDate();
      // var month = (date.getMonth() + 1 < 10)? "0" + (date.getMonth() + 1) : date.getMonth();
      // return day + "-" + month + "-" + date.getFullYear();
      return [date.getFullYear(), date.getMonth(), date.getDate()];
    },
    setCountDownTimer() {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      this.countDownDistance = this.countDownTime - now;

      // Time calculations for days, hours, minutes and seconds
      this.countDownDays = Math.floor(this.countDownDistance / (1000 * 60 * 60 * 24));
      this.countDownHours = Math.floor((this.countDownDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.countDownMinutes = Math.floor((this.countDownDistance % (1000 * 60 * 60)) / (1000 * 60));
      this.countDownSeconds = Math.floor((this.countDownDistance % (1000 * 60)) / 1000);
    },
    fetchData(symbol) {
      let self = this;
      var date = new Date();
      var minutes = date.getMinutes() - date.getMinutes() % 5;
      minutes = (minutes < 10)? "0" + minutes.toString() : minutes;
      var hour = (date.getHours() < 10)? "0" + date.getHours() : date.getHours();
      var day = (date.getDate() < 10)? "0" + date.getDate() : date.getDate();
      var month = (date.getMonth() + 1 < 10)? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var today = day + "-" + month + "-" + date.getFullYear();
      console.log(today);

      let url = 'https://option-chain-data.s3.ap-south-1.amazonaws.com/' + symbol.toLowerCase() + '/' + symbol.toLowerCase() + '_option_chain_' + today + '_'+ hour + '%3A' + minutes;
      if (!this.isMarketHours(date)) {
        if (this.countDownTime === 0) {
          let nextdt = this.getNextMarketOpen(new Date());
          this.countDownTime = new Date(nextdt[0], nextdt[1], nextdt[2], 9, 15, 0, 0).getTime();
          var x = setInterval(function() {
            self.setCountDownTimer();
            if (self.countDownDistance < 0) {
              clearInterval(x);
              self.isMarketOpen = true;
            }
          }, 1000);
        }
        this.isMarketOpen = false;
        today = this.getPrevMarketClose(date);
        url = 'https://option-chain-data.s3.ap-south-1.amazonaws.com/' + symbol.toLowerCase() + '/' + symbol.toLowerCase() + '_option_chain_' + today + '_15%3A30';
      }
      else {
        this.isMarketOpen = true;
      }
      // console.log(url);

      fetch(url, {
      // fetch('https://option-chain-data.s3.ap-south-1.amazonaws.com/' + symbol.toLowerCase() + '/' + symbol.toLowerCase() + '_option_chain_17-08-2023_15%3A30', {
      // fetch('https://option-chain-data.s3.ap-south-1.amazonaws.com/'+ symbol.toLowerCase() + '_option_chain_02-08-2023_00%3A50', {
        'method': "GET",
        'mode': 'cors',
        'headers': {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        if (response.status == 200) {
          response.json().then(OCData => {
            if (self.dayOpen == 0) {
              self.dayOpen = OCData['currentMarketPrice'];
              [self.doResistance, self.doSupport, self.doBuy, self.doSell] = self.calculateGannSquareOf9(self.dayOpen);
            }
            let strikeDivision = 100;
            if (self.currentSymbol == "NIFTY" || self.currentSymbol == "FINNIFTY") {
              strikeDivision = 50;
            }
            self.currentStrike = OCData['currentMarketPrice'] - (OCData['currentMarketPrice'] % strikeDivision)
            self.firstStrike = self.currentStrike - 500;
            self.lastStrike = self.currentStrike + 500;
            self.currentExpiry = Object.keys(OCData['data'])[0];
            self.loadData(OCData['data'][self.currentExpiry]);
            self.globalData = OCData['data'];
          });
        }
      });
    },
    fetchDayOpen(symbol) {
      let self = this;
      var date = new Date();
      var day = (date.getDate() < 10)? "0" + date.getDate() : date.getDate()
      var month = (date.getMonth() + 1 < 10)? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var today = day + "-" + month + "-" + date.getFullYear();

      this.currentSymbol = symbol;

      let url = 'https://option-chain-data.s3.ap-south-1.amazonaws.com/' + symbol.toLowerCase() + '/' + symbol.toLowerCase() + '_option_chain_' + today + '_09%3A20';
      if (!this.isMarketHours(date)) {
        this.isMarketOpen = false;
        today = this.getPrevMarketClose(date);
        url = 'https://option-chain-data.s3.ap-south-1.amazonaws.com/' + symbol.toLowerCase() + '/' + symbol.toLowerCase() + '_option_chain_' + today + '_09%3A20';
      }
      else {
        this.isMarketOpen = true;
      }
      
      fetch(url, {
      // fetch('https://option-chain-data.s3.ap-south-1.amazonaws.com/' + symbol.toLowerCase() + '/' + symbol.toLowerCase() + '_option_chain_17-08-2023_09%3A20', {
      // fetch('https://option-chain-data.s3.ap-south-1.amazonaws.com/'+ symbol.toLowerCase() + '_option_chain_02-08-2023_00%3A50', {
        'method': "GET",
        'mode': 'cors',
        'headers': {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        if (response.status == 200) {
          response.json().then(OCData => {
            self.dayOpen = OCData['currentMarketPrice'];
            [self.doResistance, self.doSupport, self.doBuy, self.doSell] = self.calculateGannSquareOf9(self.dayOpen);
          });
        }
      });
    },
    scrollToView() {
      setTimeout(() => {
        var element = this.$refs.targetRef[0];
        if (element !== undefined) {
          var headerOffset = 400;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          var loffsetPosition = element.getBoundingClientRect().left + window.pageXOffset;

          window.scrollTo({
            top: offsetPosition,
            left: loffsetPosition - 140,
            behavior: "smooth"
          });
        }

      }, 1000);
    },
    calculateGannSquareOf9(ltp) {
      // Four variables 
      // 1. ltp 
      // 2. sqrt(ltp) 
      // 3. if (2) is int: (2) + 1 Else ceiling of (2)
      // 4. floor of (2)
      // 5. (4) - 1
      // let ltp = '19343.6';
      let values = [];
      let sqrtOfLtp = Math.sqrt(ltp).toFixed(2);
      let ceilOfSqrt = parseFloat((sqrtOfLtp % 1 === 0)? sqrtOfLtp + 1 : Math.ceil(sqrtOfLtp)).toFixed(2);
      let floorOfSqrt = Math.floor(sqrtOfLtp).toFixed(2);
      let floorMinusOne = parseInt((floorOfSqrt - 1).toFixed(2));
      
      values = [floorMinusOne, sqrtOfLtp, ceilOfSqrt];

      let gannTable = [];
      let visited = [];
      for (let i = 0; i < 7; i++) {
        gannTable.push([0,0,0,0,0,0,0]);
        visited.push([0,0,0,0,0,0,0]);
      }

      let resistance = [];
      let support = [];
      let buy = 0;
      let sell = 0;

      /*
        Go square by square from inside to outside (4 squares)
      */
      let squareStart = {'x': 3, 'y': 3, 'increment': 0, 'jump': 0}

      // First square: Middle number is always square of floorMinusOne
      gannTable[squareStart['x']][squareStart['y']] = Math.pow((floorMinusOne + squareStart['increment']), 2);

      // Other square: explore all 9 angles
      // First Angle
      for (let i = 0; i < 3; i++) {
        let value = parseInt(values[i]);
        squareStart['y'] -= 1;
        squareStart['x'] = 3;
        squareStart['increment'] = 0.125;
        squareStart['jump'] += 1;
        gannTable[squareStart['x']][squareStart['y']] = Math.pow((value + squareStart['increment']), 2).toFixed(2);
        squareStart['x'] -= squareStart['jump'];
        squareStart['increment'] += 0.125;
        gannTable[squareStart['x']][squareStart['y']] = Math.pow((value + squareStart['increment']), 2).toFixed(2);
        squareStart['y'] += squareStart['jump'];
        squareStart['increment'] += 0.125;
        gannTable[squareStart['x']][squareStart['y']] = Math.pow((value + squareStart['increment']), 2).toFixed(2);
        squareStart['y'] += squareStart['jump'];
        squareStart['increment'] += 0.125;
        gannTable[squareStart['x']][squareStart['y']] = Math.pow((value + squareStart['increment']), 2).toFixed(2);
        squareStart['x'] += squareStart['jump'];
        squareStart['increment'] += 0.125;
        gannTable[squareStart['x']][squareStart['y']] = Math.pow((value + squareStart['increment']), 2).toFixed(2);
        squareStart['x'] += squareStart['jump'];
        squareStart['increment'] += 0.125;
        gannTable[squareStart['x']][squareStart['y']] = Math.pow((value + squareStart['increment']), 2).toFixed(2);
        squareStart['y'] -= squareStart['jump'];
        squareStart['increment'] += 0.125;
        gannTable[squareStart['x']][squareStart['y']] = Math.pow((value + squareStart['increment']), 2).toFixed(2);
        squareStart['y'] -= squareStart['jump'];
        squareStart['increment'] += 0.125;
        gannTable[squareStart['x']][squareStart['y']] = Math.pow((value + squareStart['increment']), 2).toFixed(2);
      }

      for (let i = 0; i < 3; i++) {
        
        // Check where does to place the ltp in gann tablez
        if (i == 1) {
          if (ltp >= gannTable[4][2] && ltp < gannTable[3][1]) {
            gannTable[4][1] = ltp;
            resistance[0] = gannTable[1][1];
            resistance[1] = gannTable[1][3];
            resistance[2] = gannTable[1][5];
            resistance[3] = gannTable[3][5];
            resistance[4] = gannTable[5][5];
            support[0] = gannTable[4][3];
            support[1] = gannTable[4][4];
            support[2] = gannTable[3][4];
            support[3] = gannTable[2][4];
            support[4] = gannTable[2][3];
            buy = gannTable[3][1];
            sell = gannTable[4][2];
          }
          else if (ltp >= gannTable[3][1] && ltp < gannTable[1][1]) {
            gannTable[2][1] = ltp;
            resistance[0] = gannTable[1][3];
            resistance[1] = gannTable[1][5];
            resistance[2] = gannTable[3][5];
            resistance[3] = gannTable[5][5];
            resistance[4] = gannTable[5][3];
            support[0] = gannTable[4][2];
            support[1] = gannTable[4][3];
            support[2] = gannTable[4][4];
            support[3] = gannTable[3][4];
            support[4] = gannTable[2][4];
            buy = gannTable[1][1];
            sell = gannTable[3][1];
          }
          else if (ltp >= gannTable[1][1] && ltp < gannTable[1][3]) {
            gannTable[1][2] = ltp;
            resistance[0] = gannTable[1][5];
            resistance[1] = gannTable[3][5];
            resistance[2] = gannTable[5][5];
            resistance[3] = gannTable[5][3];
            resistance[4] = gannTable[5][1];
            support[0] = gannTable[3][1];
            support[1] = gannTable[4][2];
            support[2] = gannTable[4][3];
            support[3] = gannTable[4][4];
            support[4] = gannTable[3][4];
            buy = gannTable[1][3];
            sell = gannTable[1][1];
          }
          else if (ltp >= gannTable[1][3] && ltp < gannTable[1][5]) {
            gannTable[1][4] = ltp;
            resistance[0] = gannTable[3][5];
            resistance[1] = gannTable[5][5];
            resistance[2] = gannTable[5][3];
            resistance[3] = gannTable[5][1];
            resistance[4] = gannTable[3][0];
            support[0] = gannTable[1][1];
            support[1] = gannTable[3][1];
            support[2] = gannTable[4][2];
            support[3] = gannTable[4][3];
            support[4] = gannTable[4][4];
            buy = gannTable[1][5];
            sell = gannTable[1][3];
          }
          else if (ltp >= gannTable[1][5] && ltp < gannTable[3][5]) {
            gannTable[2][5] = ltp;
            resistance[0] = gannTable[5][5];
            resistance[1] = gannTable[5][3];
            resistance[2] = gannTable[5][1];
            resistance[3] = gannTable[3][0];
            resistance[4] = gannTable[0][0];
            support[0] = gannTable[1][3];
            support[1] = gannTable[1][1];
            support[2] = gannTable[3][1];
            support[3] = gannTable[4][2];
            support[4] = gannTable[4][3];
            buy = gannTable[3][5];
            sell = gannTable[1][5];
          }
          else if (ltp >= gannTable[3][5] && ltp < gannTable[5][5]) {
            gannTable[4][5] = ltp;
            resistance[0] = gannTable[5][3];
            resistance[1] = gannTable[5][1];
            resistance[2] = gannTable[3][0];
            resistance[3] = gannTable[0][0];
            resistance[4] = gannTable[0][3];
            support[0] = gannTable[1][5];
            support[1] = gannTable[1][3];
            support[2] = gannTable[1][1];
            support[3] = gannTable[3][1];
            support[4] = gannTable[4][2];
            buy = gannTable[5][5];
            sell = gannTable[3][5];
          }
          else if (ltp >= gannTable[5][5] && ltp < gannTable[5][3]) {
            gannTable[5][4] = ltp;
            resistance[0] = gannTable[5][1];
            resistance[1] = gannTable[3][0];
            resistance[2] = gannTable[0][0];
            resistance[3] = gannTable[0][3];
            resistance[4] = gannTable[0][6];
            support[0] = gannTable[3][5];
            support[1] = gannTable[1][5];
            support[2] = gannTable[1][3];
            support[3] = gannTable[1][1];
            support[4] = gannTable[3][1];
            buy = gannTable[5][3];
            sell = gannTable[5][5];
          }
          else if (ltp >= gannTable[5][3] && ltp < gannTable[5][1]) {
            gannTable[5][2] = ltp;
            resistance[0] = gannTable[3][0];
            resistance[1] = gannTable[0][0];
            resistance[2] = gannTable[0][3];
            resistance[3] = gannTable[0][6];
            resistance[4] = gannTable[3][6];
            support[0] = gannTable[5][5];
            support[1] = gannTable[3][5];
            support[2] = gannTable[1][5];
            support[3] = gannTable[1][3];
            support[4] = gannTable[1][1];
            buy = gannTable[5][1];
            sell = gannTable[5][3];
          }
        }
        else if (i == 2) {
          if (ltp >= gannTable[5][1] && ltp < gannTable[3][0]) {
            gannTable[5][0] = ltp;
            resistance[0] = gannTable[0][0];
            resistance[1] = gannTable[0][3];
            resistance[2] = gannTable[0][6];
            resistance[3] = gannTable[3][6];
            resistance[4] = gannTable[6][6];
            support[0] = gannTable[5][3];
            support[1] = gannTable[5][5];
            support[2] = gannTable[3][5];
            support[3] = gannTable[1][5];
            support[4] = gannTable[1][3];
            buy = gannTable[3][0];
            sell = gannTable[5][1];
          }
          else if (ltp >= gannTable[3][0] && ltp < gannTable[0][0]) {
            gannTable[2][0] = ltp;
            resistance[0] = gannTable[0][3];
            resistance[1] = gannTable[0][6];
            resistance[2] = gannTable[3][6];
            resistance[3] = gannTable[6][6];
            resistance[4] = gannTable[6][3];
            support[0] = gannTable[5][1];
            support[1] = gannTable[5][3];
            support[2] = gannTable[5][5];
            support[3] = gannTable[3][5];
            support[4] = gannTable[1][5];
            buy = gannTable[0][0];
            sell = gannTable[3][0];
          }
          else if (ltp >= gannTable[0][0] && ltp < gannTable[0][3]) {
            gannTable[0][1] = ltp;
            resistance[0] = gannTable[0][6];
            resistance[1] = gannTable[3][6];
            resistance[2] = gannTable[6][6];
            resistance[3] = gannTable[6][3];
            resistance[4] = gannTable[6][0];
            support[0] = gannTable[3][0];
            support[1] = gannTable[5][1];
            support[2] = gannTable[5][3];
            support[3] = gannTable[5][5];
            support[4] = gannTable[3][5];
            buy = gannTable[0][3];
            sell = gannTable[0][0];
          }
          else if (ltp >= gannTable[0][3] && ltp < gannTable[0][6]) {
            gannTable[0][4] = ltp;
            resistance[0] = gannTable[3][6];
            resistance[1] = gannTable[6][6];
            resistance[2] = gannTable[6][3];
            resistance[3] = gannTable[6][0];
            resistance[4] = gannTable[6][0];
            support[0] = gannTable[0][0];
            support[1] = gannTable[3][0];
            support[2] = gannTable[5][1];
            support[3] = gannTable[5][3];
            support[4] = gannTable[5][5];
            buy = gannTable[0][6];
            sell = gannTable[0][3];
          }
          else if (ltp >= gannTable[0][6] && ltp < gannTable[3][6]) {
            gannTable[1][6] = ltp;
            resistance[0] = gannTable[6][6];
            resistance[1] = gannTable[6][3];
            resistance[2] = gannTable[6][0];
            resistance[3] = gannTable[6][0];
            resistance[4] = gannTable[6][6];
            support[0] = gannTable[0][3];
            support[1] = gannTable[0][0];
            support[2] = gannTable[3][0];
            support[3] = gannTable[5][1];
            support[4] = gannTable[5][3];
            buy = gannTable[3][6];
            sell = gannTable[0][6];
          }
          else if (ltp >= gannTable[3][6] && ltp < gannTable[6][6]) {
            gannTable[4][6] = ltp;
            resistance[0] = gannTable[6][3];
            resistance[1] = gannTable[6][0];
            resistance[2] = gannTable[6][0];
            resistance[3] = gannTable[6][6];
            resistance[4] = gannTable[6][6];
            support[0] = gannTable[0][6];
            support[1] = gannTable[0][3];
            support[2] = gannTable[0][0];
            support[3] = gannTable[3][0];
            support[4] = gannTable[5][1];
            buy = gannTable[6][6];
            sell = gannTable[3][6];
          }
          else if (ltp >= gannTable[6][6] && ltp < gannTable[6][3]) {
            gannTable[6][5] = ltp;
            resistance[0] = gannTable[6][0];
            resistance[1] = gannTable[6][0];
            resistance[2] = gannTable[6][6];
            resistance[3] = gannTable[6][6];
            resistance[4] = gannTable[6][6];
            support[0] = gannTable[3][6];
            support[1] = gannTable[0][6];
            support[2] = gannTable[0][3];
            support[3] = gannTable[0][0];
            support[4] = gannTable[3][0];
            buy = gannTable[6][3];
            sell = gannTable[6][6];
          }
          else if (ltp >= gannTable[6][3] && ltp < gannTable[6][0]) {
            gannTable[6][2] = ltp;
            resistance[0] = gannTable[6][0];
            resistance[1] = gannTable[6][6];
            resistance[2] = gannTable[6][6];
            resistance[3] = gannTable[6][6];
            resistance[4] = gannTable[6][6];
            support[0] = gannTable[6][6];
            support[1] = gannTable[3][6];
            support[2] = gannTable[0][6];
            support[3] = gannTable[0][3];
            support[4] = gannTable[0][0];
            buy = gannTable[6][0];
            sell = gannTable[6][3];
          }
        }
      }
      
      // console.log("Gann Table");
      // console.log(gannTable);

      // Log to console
      // console.log(ltp + ' ' + sqrtOfLtp + ' ' + ceilOfSqrt + ' ' + floorOfSqrt + ' ' + floorMinusOne);
      // console.log(resistance + ' ' + support + " " + buy + " " + sell);

      // this.strikeResistance = resistance;
      // this.strikeSupport = support;
      // if (this.strikeBuy == 0 || this.strikeSell == 0) {
      //   this.strikeBuy = buy;
      //   this.strikeSell = sell;
      // }
      return [resistance, support, buy, sell]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tooltipText {
  background-color: white;
  color: #2c3e50;
  position: absolute;
  top: 68px;
  left: 42px;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  display: none;
  transition: all .5s;
  z-index: 999999;
  border: 2px solid #214797;
  max-width: 50%;
  font-weight: normal;
}
.tooltipText::after {
    content: '';
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    bottom: 100%;
    left: 56px;
    margin-left: 5%;
}
.release-version:hover .tooltipText{
    display: block;
    transform: translateY(-10px);
}
#closedBannerTop {
  position: fixed;
  width: 100%;
  height: 42px;
  top: 60px;
  /* background: #517cbb; */
  background: #f8d629;
  line-height: 40px;
  z-index: 9;
}
#market-closed-banner {
  position: fixed;
  background: #ffffffb0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.option-chain-data-row {
  cursor: pointer;
}
#content-table {
  margin-top: 60px;
}
.option-chain-table {
  display: grid;
  grid-template-columns: 300px 150px 300px;
}
.option-chain-call-table-row, .option-chain-put-table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.line-height-40 {
  line-height: 40px;
}
.option-chain-table-row {
  padding: 10px;
  border-bottom: 0.1rem solid lightgray;
}
.option-chain-put-headers {
  /* background: #f2dede; */
  /* background: #d15e5e;
  color: white; */
  background: white;
  color: #2c3e50;
  font-weight: bold;
  border: none;
  box-shadow: 3px 1px 8px 2px rgb(230 230 230);
}
.option-chain-strike-headers {
  /* background: lightgray; */
  background: #2c3e50;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: 3px 1px 8px 2px rgb(230 230 230);
}
.option-chain-strike-col {
  background: #d3d3d33d;
}
.option-chain-call-headers {
  /* background: #d4f8d8; */
  /* background: #34993f;
  color: white; */
  background: white;
  color: #2c3e50;
  font-weight: bold;
  border: none;
  box-shadow: 3px 1px 8px 2px rgb(230 230 230);
  /* border: 1px solid lightgray; */
}
.option-chain-call-table-col {
  display: grid;
  line-height: 40px;
  grid-gap: 8px;
}
.option-chain-call-table-col-with-percent {
  grid-template-columns: 60px 60px;
  /* position: relative; */
}
.option-chain-call-table-col-with-percent > :first-child {
  text-align: right;
  /* font-size: 0.9em; */
}
.option-chain-call-table-col-with-percent > .percent-change {
  text-align: left;
}
.percent-change {
  font-size: 0.75em;
}
.percent-above {
  color: red;
  line-height: 38px;
  padding-top: 2px;
  width: 80px;
  max-height: 38px;
}
.percent-below {
  color: green;
  line-height: 38px;
  padding-top: 2px;
  width: 80px;
  max-height: 38px;
}
.header {
  text-align: left;
  /* padding: 15px 20px; */
  background: #214797;
  color: white;
  height: 30px;
  /* width: 100%; */
}
.sticky-1 {
  /* position: fixed!important; */
  top: 0;
  left: 0;
  right: 0;
}
.sticky-2 {
  top: 60px;
}
.sticky-2-with-banner {
  top: 102px;
}
.sticky-3 {
  /* top: 102px; */
  top: 60px;
}
.sticky-3-with-banner {
  /* top: 144px; */
  top: 102px;
}
.sticky-header {
  position: sticky;
}
#logo {
  font-size: 1em;
  font-weight: bold;
  padding: 8px 20px;
  line-height: 30px;
}
.current-strike {
  background: #bacce457;
}
body {
  font-size: 0.6em;
}
.strike-price-val {
  /* text-decoration: underline; */
  /* cursor: pointer; */
}
.unfilled-void {
  line-height: 40px;
}
.release-version {
  font-size: 0.49em;
  line-height: 16px;
  width: fit-content;
  cursor: pointer;
}
.doc-release-version {
  color: darkgray;
  position: relative;
  margin-top: 16px;
  font-weight: bold;
}
.doc-release-version:after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 90px;
  right: 0;
  border: 1px solid #dadada;
  z-index: -1;
}
@media only screen and (min-width: 600px) {
  .option-chain-table {
    display: grid;
    grid-template-columns: 1fr 150px 1fr;
  }
  .header {
    font-size: 1.5em;
  }
}
@media only screen and (max-width: 600px) {
  .tooltipText {
    max-width: 64%;
  }
  .option-chain-table {
    grid-template-columns: 1fr 120px 1fr;
  }
  #logo {
    padding: 8px 20px;
    line-height: 16px;
    /* text-align: center; */
    font-size: 14px;
  }
  #option-chain-brand {
    display: block;
  }
  #sm-stock-market {
    display: grid;
    grid-gap: 30px;
  }
  #sm-stock-market img {
    margin: auto!important;
  }
  .countdown-timer-popup {
    display: block;
  }
  /* .modal-container {
    font-size: 30px!important;
    width: 80%;
  }
  .modal-header div {
    font-size: 30px!important;
  } */
  #sm-stock {
    width: 170px!important;
  }
  #sm-market {
    width: 220px!important;
  }
  #sm-closed {
    width: 200px!important;
  }
  #market-closed-banner > div {
    width: 250px!important;
    padding: 70px 50px;
  }
  #market-closed-banner {
    font-size: 14px!important;
  }

  .option-chain-call-table-col-with-percent {
    display: block;
  }
  .option-chain-call-table-col-with-percent > :first-child {
    text-align: center;
  }
  .option-chain-call-table-col-with-percent > .percent-change {
    text-align: center;
    line-height: 10px;
  }
  .option-chain-strike-col {
    line-height: 52px!important;
  }
  .unfilled-void {
    line-height: 52px;
  }
  .tooltipText::after {
    left: 34px;
  }
}

</style>
